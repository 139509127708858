import {
  ApplicationConfig,
  enableProdMode,
  importProvidersFrom
} from '@angular/core';
import { QuillModule } from 'ngx-quill';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarConfig
} from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { JWT_OPTIONS_PROVIDER } from '@app/core/providers';
import { appRoutes } from '@app/app.routes';
import { RootStoreModule } from '@app/store';
import { TimeagoModule } from '@app/libs/ticket/features/tickets/pipes/timeago';
import { MatNativeDateModule } from '@angular/material/core';
import { StoreModule } from '@ngrx/store';
import {
  notificationsHistoryFeature,
  notificationsHistoryPageEffects
} from '@app/libs/account-notification/features/notifications-history/state';
import { EffectsModule } from '@ngrx/effects';
import { ColorPickerModule } from 'ngx-color-picker';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorDefaultOptions,
  MatPaginatorIntl
} from '@angular/material/paginator';
import { DEFAULT_INITIAL_PAGINATION_DATA } from '@app-ngrx-utils/constants';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions
} from '@angular/material/form-field';
import { Toasty } from '@models/toasty.enum';
import { environment } from '@env/environment';
import { PaginatorIntlService } from '@shared/services/paginator-intl.service';
import { provideRouter } from '@angular/router';
import {
  popperVariation,
  provideTippyConfig,
  provideTippyLoader,
  tooltipVariation
} from '@ngneat/helipopper/config';
import tippy from 'tippy.js';

if (environment.production) {
  enableProdMode();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    importProvidersFrom(
      BrowserModule,
      JwtModule.forRoot({ jwtOptionsProvider: JWT_OPTIONS_PROVIDER }),
      RootStoreModule,
      TimeagoModule.forChild(),
      MatNativeDateModule,
      StoreModule.forFeature(notificationsHistoryFeature),
      EffectsModule.forFeature(notificationsHistoryPageEffects),
      QuillModule.forRoot(),
      ColorPickerModule,
      FullCalendarModule,
      MatDialogModule
    ),
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'outline',
        hidePageSize: false,
        pageSize: DEFAULT_INITIAL_PAGINATION_DATA.pageSize,
        pageSizeOptions: DEFAULT_INITIAL_PAGINATION_DATA.pageOptions,
        showFirstLastButtons: false
      } as MatPaginatorDefaultOptions
    },
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorIntlService
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: <MatFormFieldDefaultOptions>{
        appearance: 'outline',
        floatLabel: 'auto',
        hideRequiredMarker: false
      }
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'outline',
        hidePageSize: false,
        pageSize: 10,
        pageSizeOptions: [10, 25, 50],
        showFirstLastButtons: false
      } as MatPaginatorDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        panelClass: Toasty.Info,
        duration: 3000
      } as MatSnackBarConfig
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideTippyLoader(() => tippy),
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation
      }
    })
  ]
};

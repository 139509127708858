import { Routes } from '@angular/router';

import { AuthGuard } from '@app/libs/auth/guards';
import { RoleGuard } from '@app/libs/auth/guards/role.guard';
import { DevelopmentGuard } from '@app/libs/auth/guards/development.guard';

export const appRoutes: Routes = [
  {
    path: 'sign-in',
    loadChildren: () =>
      import('@app/libs/auth/auth-shell.routes').then((r) => r.authShellRoutes)
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    loadChildren: () =>
      import('@app/core/core-shell.routes').then((r) => r.coreShellRoutes)
  },
  {
    path: 'ui-kit',
    loadChildren: () =>
      import('@app/ui-kit/ui-kit-feature.routes').then(
        (r) => r.uiKitFeatureRoutes
      ),
    canActivate: [DevelopmentGuard]
  },
  { path: '', redirectTo: '/app/dashboard', pathMatch: 'full' }
];
